import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Helmet from "react-helmet"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Container from "../components/Container"

const Index = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            titleHead
            title
          }
        }
      }
    `
  )
  return (
    <Layout title={site.siteMetadata.titleHead}>
      <Helmet title={site.siteMetadata.title} />
      <Container>
        <div css={css` width: 55%; margin-right: 0;}`}>
          <h2>
            <em>Engineer/Scientist</em>
          </h2>
          <p>
            Currently, I'm finishing a PhD program in{" "}
            <b>aerospace engineering sciences</b> at the University of Colorado
            Boulder &mdash; establishing an interdisciplinary background in the{" "}
            <b>engineering of space systems</b> and{" "}
            <b>earth sciences research</b>. In these past few years in graduate
            school, I have delivered successful engineering solutions to a wide
            range of both ground and space missions, by leveraging direct
            experience in <b>project formulation</b>, <b>systems engineering</b>
            , <b>instrument design</b>, <b>embedded systems</b>, and{" "}
            <b>atmospheric research</b>. Some highlights include: field research
            and data post-processing for the{" "}
            <a
              href="https://sites.google.com/illinois.edu/relampago/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              RELAMPAGO campaign
            </a>
            , development of the{" "}
            <a
              href="https://www.colorado.edu/aerospace/2017/05/25/qb50-challenger-satellite-live"
              target="_blank"
              rel="noopener noreferrer"
            >
              Challenger QB50
            </a>{" "}
            CubeSat's Attitude Determination and Control Subsystem (ADCS), and
            collaboration on project formulation and systems engineering tasks
            for the{" "}
            <a
              href="https://techport.nasa.gov/view/95778"
              target="_blank"
              rel="noopener noreferrer"
            >
              AEPEX CubeSat
            </a>{" "}
            concept development. My PhD research focuses on the study of{" "}
            <a
              href="https://culair.weebly.com/lightning.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              lightning occurrence in thunderstorms
            </a>
            , through the application of data assimilation techniques &mdash;
            which are also relevant in satellite orbit and attitude
            determination &mdash; on satellite and ground based remote sensing
            data.
          </p>
        </div>
        <div
          css={css` width: 25%; @media only screen and (max-width: 1200px) {width: clamp(25vw, 25vh, 80vw); margin: 10%;}}`}
        >
          <StaticImage
            css={css` border-radius:1rem;}`}
            src="../../assets/images/alas.jpg"
            alt="Andre's Portrait"
            placeholder="blurred"
            layout="fullWidth"
          />
        </div>
      </Container>
    </Layout>
  )
}
export default Index

Index.propTypes = {}
